import * as Sentry from '@sentry/browser';
import Router from 'next/router';

import { CLIENT_INTERCOM_QUERY } from '@app-lib/account/query';
import { User } from '@app-lib/account/typings';
import { SignInFormValues } from '@app-lib/authentication/typings';

import { getClient } from '../../../core/apollo';
import config from '../../../core/config';
import * as browserHelpers from '../../../core/helper/browser';
import * as sentryHelper from '../../../core/helper/sentry';
import { Nullable } from '../../../core/typings';
import { Routes } from '../../../typings';

const INVALID_COOKIE_ERROR_MESSAGE: string =
  'Network error: Response not successful: Received status code 500';
const userIdCookieName: string = 'user-id';

export function handleInvalidUserIdCookie(error: Error): void {
  if (error.message === INVALID_COOKIE_ERROR_MESSAGE) {
    browserHelpers.deleteCookie(userIdCookieName);
  }
}

export interface HandleSignInParams extends SignInFormValues {
  signIn: (email: string, password: string) => Promise<Nullable<User>>;
}

function registerSentryUser(user: User): void {
  const profiledUser = {
    id: user.id.toString(),
    email: user.email,
    name: user.fullname,
  };
  Sentry.setUser(profiledUser);
}

async function registerIntercomUser(): Promise<void> {
  const client = getClient();
  const {
    data: { intercom: intercomUserData },
  } = await client.query({
    query: CLIENT_INTERCOM_QUERY,
  });
  window.Intercom('boot', {
    app_id: config.intercomApiKey,
    created_at: new Date().getTime(),
    user_id: intercomUserData.user_id.toString(),
    email: intercomUserData.email,
    user_hash: intercomUserData.web_user_hash,
    name: intercomUserData.fullname,
  });
}

export async function handleSignIn({
  signIn,
  email,
  password,
}: HandleSignInParams): Promise<void> {
  try {
    const user: Nullable<User> = await signIn(email, password);
    if (user === null) return;
    registerSentryUser(user);
    registerIntercomUser();
    await Router.push(Routes.HOME);
  } catch (err) {
    handleInvalidUserIdCookie(err);
    sentryHelper.captureException(err, { email });
  }
}
